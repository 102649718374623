@import '../../styles-mobile/widgets/widget-wrapper';

.react-widget-wrapper {
  margin-bottom: 52px;
  padding-bottom: 0;

  @media screen and (min-width: 628px) {
    &:not(.vertical) {
      margin: 24px 0;
      padding: 16px 0;
      border-top: var(--divider-line-widget);
      border-bottom: var(--divider-line-widget);
    }
  }

  &__header {
    margin: 0;
    padding: 0 0 var(--space-2);
    border-bottom: var(--divider-line-widget);
  }

  &__link {
    gap: var(--space-4);
  }
}

.sidebar-react {
  .react-widget-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
}
